const initialState = {
  pageIndex: 0,
}

const actions = {
  MOVE_PAGE: "REDUX/MOVE_PAGE",
}

export const movePage = pageTo => ({ type: actions.MOVE_PAGE, pageTo })

const main = (state = initialState, action) => {
  switch (action.type) {
    case actions.MOVE_PAGE:
      return { ...state, pageIndex: action.pageTo }
    default:
      return state
  }
}

export default main
